<template>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">                
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" />
					</template>
				</Toolbar>
				<DataTable ref="dt" :value="contadores" class="p-datatable-sm" dataKey="id_contador" :paginator="true" :rows="10" :filters="filters"
							:globalFilterFields="['numero_contador', 'tarifa', 'inmueble', 'observaciones']" 
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}" :loading="loadingGrd">
					<template #header>
						<div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
							<h5 class="p-m-0">Contadores</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="numero_contador" header="Número" :sortable="true" headerStyle="width: 10%"/>
					<Column field="inmueble"  header="Inmueble" :sortable="true" headerStyle="width: 25%"/>
					<Column field="contador_tipo" header="Tipo" headerStyle="width: 15%"/>
					<Column field="tarifa"  header="Tarifa" :sortable="true" headerStyle="width: 20%"/>
					<Column field="observaciones"  header="Observaciones" :sortable="true" headerStyle="width: 20%"/>
							
					<!-- <Column field="utiliza_tarifa_escalonada" header="Utiliza Escalonada" headerStyle="width: 10%">
					</Column>		 -->
					<Column field="activo" header="Activo" headerStyle="text-align: center">
						<template #body="slotProps">							
							<i v-if="slotProps.data.activo=='N'" class="pi pi-circle-off"></i>
							<i v-if="slotProps.data.activo=='S'" class="pi pi-circle-on"></i>							
						</template>			
					</Column>																							
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-1" @click="edit(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning p-mr-1" @click="confirmDelete(slotProps.data)" />
						</template>
					</Column>
				</DataTable>
			</div>
			<MdsBuscarInmueble :mostrar="inmuebleDialog" @cancelar="cerrarDlgInmueble" @seleccionar="elegirInmueble"/>
			<Dialog v-model:visible="contadorDlg" :style="{width: '850px'}"  :modal="true" class="p-fluid">
				<div class="p-fluid p-formgrid p-grid">
					<div class="p-field p-col-10">
						<label for="inmueble">Inmueble</label>
						<div class="p-inputgroup">
							<InputText size="135" id="inmueble" type="text" v-model="contador.inmueble" disabled/>																							
							<Button icon="pi pi-search" @click="seleccionarInmueble" />							
						</div>
					</div>	
					<div class="p-field p-col-2 p-d-flex p-as-end">
						<label class="p-col" for="activo">Activo</label>
						<InputSwitch v-model="contador.activoDisplay"/>
					</div>							
					<div class="p-field p-col-6">
						<label for="id_contador_tipo">Tipo</label>
						<Dropdown v-model="contador.id_contador_tipo" required="true" :options="lkpContadorTipos" optionLabel="contador_tipo" optionValue="id_contador_tipo" placeholder="Seleccionar..." />
					</div>
					<div class="p-field p-col-6">
						<label for="id_contador_tarifa">Tarifa</label>
						<Dropdown v-model="contador.id_contador_tarifa" required="true" :options="lkpTarifas" optionLabel="tarifa" optionValue="id_contador_tarifa" placeholder="Seleccionar..." />
					</div>
					<div class="p-field  p-col-6">
						<label for="numero_contador">Número</label>
						<InputText :disabled="contador.id_contador!=''" v-model="contador.numero_contador" maxlength="20" />                    				
					</div>
					<div class="p-field  p-col-6">
						<label for="observaciones">Observaciones</label>
						<InputText v-model="contador.observaciones" maxlength="100" />                    				
					</div>					
				</div>                                                                                                              								
				<template #footer>
					<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="contadorDlg = false"/>
					<Button label="Guardar" icon="pi pi-check" class="p-button-text" @click="guardarRec"/>						
				</template>
			</Dialog>
			<Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
				<div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="contador">Esta seguro que desea eliminar el contador seleccionado?<br><b>[{{ contador.numero_contador }}]</b>?</span>
				</div>
				<template #footer>
					<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteDialog = false"/>
					<Button label="Sí" icon="pi pi-check" class="p-button-text" @click="deleteRec" />
				</template>
			</Dialog>
		</div>
	</div>

</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import MdsService from '../../service/mds.service';
import MdsBuscarInmueble from '../../components/MdsBuscarInmueble.vue';
import {FilterMatchMode} from 'primevue/api';

export default {
	data() {
		return {
			contadores: null,
            lkpTarifas: null,
            lkpContadorTipos: this.$store.state.auth.currentAppCtx.contadores_tipo, 			
			complejo: this.$store.state.auth.currentAppCtx.complejo,
            deleteDialog: false,
			contador: {},	
			loading: false,	
			loadingGrd: false,
			contadorDlg: false,
			filters: {'global': {value: null, matchMode: FilterMatchMode.CONTAINS}},
			inmuebleDialog: false,
			InmuebleSel: null,
			submitted: false
		}
	},
	components: {
		Loading, MdsBuscarInmueble
	},		
	async mounted() {
		this.loadingGrd = true;
		let filtro = {
			id_complejo : this.complejo.id_complejo
		};

		try {
			var data = await MdsService.getContadores(this.$store.state.auth.currentAppCtx, filtro);
			this.contadores = data;
			this.lkpTarifas = await MdsService.getContadorTarifas(this.$store.state.auth.currentAppCtx);

			this.loadingGrd = false;
		} catch(err){
			this.loadingGrd = false;
			this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})
		}
	},
	methods: {
		openNew() {
			this.contador = {
				id_inmueble: '',
				inmueble: '',
                id_contador: 0,
				numero_contador: '',
                utiliza_tarifa_escalonada: 'N',
				observaciones: '',
                id_contador_tipo: 1,
                id_contador_tarifa: this.lkpTarifas[0].id_contador_tarifa,
				activo: 'S',
				activoDisplay: true 				
			};			
			this.contadorDlg = true;			
		},
		edit(registro) {
			this.contador = registro;
			this.contador.activoDisplay = (this.contador.activo == 'S');
			this.contadorDlg = true;			
		},
		confirmDelete(registro) {
			this.contador = registro;
			this.deleteDialog = true;
		},
		async guardarRec() {						
			try {
				this.contador.activo = this.contador.activoDisplay ? 'S': 'N';
				this.loading = true;
				var esNuevo = (this.contador.id_contador == 0);
				var data = await MdsService.registrarContador(this.$store.state.auth.currentAppCtx, this.contador);

				if(esNuevo) {
					this.contador.id_contador = data.id_contador;
					var contador_tipo = this.lkpContadorTipos.filter(t=>t.id_contador_tipo == this.contador.id_contador_tipo);
					this.contador.contador_tipo = contador_tipo[0].contador_tipo;
					var tarifa = this.lkpTarifas.filter(t=>t.id_contador_tarifa == this.contador.id_contador_tarifa);
					this.contador.tarifa = tarifa[0].tarifa;
					this.contadores.push(JSON.parse(JSON.stringify(this.contador)));
				} else {					
					for (var i =0; i < this.contadores.length; i++) {
						if (this.contadores[i].id_contador == this.contador.id_contador) {
							this.contadores[i] = JSON.parse(JSON.stringify(this.contador));							
							break;
						}
					}					
				}
				this.contadorDlg = false;	

				this.$toast.add({severity:'success', summary: 'Exito', detail: 'Los cambios han sido guardados con éxito!', life: 3000});									
				this.loading = false;				
			} catch (err) {
				this.loading = false;
				this.contadorDlg = false;	
				this.$toast.add({severity:'error', summary: 'Error', detail: err})
			}
		},		
		async deleteRec() {
			this.deleteDialog = false;
			try {
				var enUso = await MdsService.contadorEnUso(this.$store.state.auth.currentAppCtx, this.contador);

				if(enUso) {
					this.$toast.add({severity:'warn', summary: 'Advertencia', detail: 'No es posible remover este contador por que tiene lecturas asociadas!', life: 3000})					
					return;
				}

				this.loadingGrd = true;
				await MdsService.eliminarContador(this.$store.state.auth.currentAppCtx, this.contador);

				this.contadores = this.contadores.filter(val => val.id_contador !== this.contador.id_contador);
				this.contador = {};
				this.loadingGrd = false;
				this.$toast.add({severity:'success', summary: 'Exito', detail: 'El contador ha sido removido!', life: 3000})

			} catch (err) {				
				this.loadingGrd = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})
			}			

		},
		seleccionarInmueble() {
			this.inmuebleDialog = true;
		},
		cerrarDlgInmueble(){
			this.inmuebleDialog = false;
		},					
		elegirInmueble(Inmueble){
			this.inmuebleDialog = false;
			this.InmuebleSel = Inmueble;		
			this.contador.id_inmueble = Inmueble.id_inmueble;
			this.contador.inmueble = Inmueble.display;
		}		
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}
</style>
